<template>
  <ValidationObserver v-if="sensorObj" v-slot="{ invalid }">
    <card id="main-wrap">
      <template slot="header">
        <h5 class="card-category">Preferenze {{ sensor.device + " (" + sensor.vendor + " " + sensor.modelName + ")" }}</h5>
        <!-- <h3 class="card-title"></h3> -->
      </template>

      <tabs type="primary">
        <tab-pane label="Generali">
          <div class="row p-2">
            <div class="col-md-12">
              <h4 :style="{ color: getOnline(sensorObj) ? 'green' : 'red' }">
                Ultima trasmissione: {{ sensorObj.lastTX ? new Date(sensorObj.lastTX).toLocaleString() : "Never" }}
              </h4>
            </div>
            <div class="col-md-6">
              <base-progress label="Batteria" value-position="right" :value="getBL(sensorObj)" />
            </div>
            <div class="col-md-6">
              <base-progress label="Segnale" value-position="right" :value="getSQ(sensorObj) || 'N.D.'" />
            </div>
            <div class="col-md-6">
              <ValidationProvider name="name" rules="required|min:3" v-slot="{ passed, failed, errors }">
                <base-input
                  @input="unsavedChanges = true"
                  required
                  v-model="sensorObj.userConfig.name"
                  label="Nome custom"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6" v-if="!staticTypeSensor">
              <ValidationProvider name="number" rules="required|numeric" v-slot="{ passed, failed, errors }">
                <base-input
                  @input="unsavedChanges = true"
                  required
                  v-model="sensorObj.keepAlive"
                  label="Keep alive (sec.)"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-12" v-if="hasNativeDistanceInput">
              <ValidationProvider name="number" rules="required|numeric|min:0" v-slot="{ passed, failed, errors }">
                <base-input
                  @input="unsavedChanges = true"
                  required
                  v-model="sensorObj.userConfig.maxDistFilterThreshold"
                  label="White Filter value (0 = spento)"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>
            </div>
          </div>
        </tab-pane>

        <!-- Input Nativi (es. temperatura, distanza, ecc) -->
        <tab-pane label="Input nativi" v-if="!staticTypeSensor">
          <tabs type="info" vertical class="row">
            <!-- Itero sugli Input Nativi -->
            <tab-pane
              v-for="(input, index) in sensorObj.inputs"
              :key="index + inputRefreshToken.toString()"
              :label="translateValueType(input.destination || input.source || '')"
              :show="input.destination === undefined"
            >
              <div class="col-12">
                <Input v-model="sensorObj.inputs[index]" :inputIndex="index" :actualInputs="sensorObj.inputs" :nativeMode="true" />
              </div>
            </tab-pane>
          </tabs>
        </tab-pane>

        <tab-pane label="Input derivati" v-if="!staticTypeSensor">
          <tabs type="info" vertical class="row">
            <!-- Itero sugli Input Derivati -->
            <tab-pane
              v-for="(input, index) in sensorObj.inputs"
              :key="index + inputRefreshToken.toString()"
              :label="translateValueType(input.destination || input.source || '')"
              :show="input.destination !== undefined"
            >
              <div class="col-12">
                <Input v-model="sensorObj.inputs[index]" :inputIndex="index" :actualInputs="sensorObj.inputs" :nativeMode="false" @remove-me="removeDerivatedInput(index)" />
              </div>
            </tab-pane>
          </tabs>
          <!-- Aggiungi input -->
          <div class="col-4">
            <div class="row mr-2">
              <base-button class="col-4 auto-m" size="sm" :wide="false" :block="true" :simple="false" type="info" @click="addDerivatedInput" round :disabled="someInvalidInput"
                >Aggiungi</base-button
              >
            </div>
          </div>
        </tab-pane>

        <tab-pane label="Contatori" v-if="!staticTypeSensor">
          <tabs type="info" vertical class="row">
            <!-- Itero sugi counter -->
            <tab-pane v-for="(counter, index) in sensorObj.counters" :key="index + counterRefreshToken.toString()" :label="translateValueType(counter.valueType || '')">
              <div class="col-12">
                <Counter
                  v-model="sensorObj.counters[index]"
                  :actualInputs="sensorObj.inputs"
                  :actualCounters="sensorObj.counters"
                  :counterIndex="index"
                  @remove-me="removeCounter(index)"
                />
              </div>
            </tab-pane>
          </tabs>
          <!-- Aggiungi counter -->
          <div class="col-4">
            <div class="row mr-2">
              <base-button class="col-4 auto-m" size="sm" :wide="false" :block="true" :simple="false" type="info" @click="addCounter" round :disabled="someInvalidCounter"
                >Aggiungi</base-button
              >
            </div>
          </div>
        </tab-pane>

        <tab-pane label="ModBus" v-if="sensorObj.modelName === 'MOD-BUS' && sensorObj.modBus">
          <ModBus v-model="sensorObj" />
        </tab-pane>

        <!-- <tab-pane label="Allarmi" v-if="!staticTypeSensor">
          Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas.
          <br /><br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
        </tab-pane> -->

        <!-- <tab-pane label="Azioni" v-if="!staticTypeSensor">
          <base-button :wide="true" :block="true" :simple="true" type="warning">Cancella Dati Sensore</base-button>
        </tab-pane> -->
      </tabs>

      <!-- <card v-if="sensor && tempInput" id="main-wrap">
    <template slot="header">
      <h3 class="card-title">Inputs</h3>
    </template>
    <div class="col-md-12 footer-col">
      <div class="row">
        <div class="col-md-6" align="center"></div>
        <div class="col-md-6" align="center"></div>
      </div>
      <div class="row">
        <div class="col-md-6" align="center">
          <base-button type="default" class="btn-full" @click="closeMe"> <i class="tim-icons icon-square-pin"></i> {{ $t("deviceSettings.undo") }} </base-button>
        </div>
        <div class="col-md-6" align="center">
          <base-button type="success" class="btn-full"> <i class="tim-icons icon-settings"></i> {{ $t("deviceSettings.save") }} </base-button>
        </div>
      </div>
    </div>
  </card> -->
      <template slot="footer">
        <div class="row">
          <div class="col-6 flex">
            <base-button size="sm" type="success" class="auto-m" @click="closeMe">Chiudi</base-button>
          </div>
          <div class="col-6 flex">
            <base-button size="sm" type="primary" class="auto-m" :disabled="invalid || someInvalidInput || someInvalidCounter" @click="save">Salva</base-button>
          </div>
        </div>
      </template>
    </card>
  </ValidationObserver>
</template>
<script>
import { TabPane, Tabs } from "src/components";
import { Table, TableColumn } from "element-ui";
import { translateDataType } from "../../../helpers/translation";
import { Select, Option } from "element-ui";
import { BaseProgress } from "src/components/index";
import { isOnline, getNormalizedBatteryLvl, getNormalizedQualitySignal, countableSources } from "../../../helpers/device";
import Input from "./SensorSettingsComponents/Input.vue";
import Counter from "./SensorSettingsComponents/Counter.vue";
import ModBus from "./SensorSettingsComponents/ModBus.vue";

export default {
  name: "InputModal",
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseProgress,
    TabPane,
    Tabs,
    Input,
    Counter,
    ModBus,
  },
  props: {
    sensor: Object,
  },
  data() {
    return {
      sensorObj: null,
      inputRefreshToken: Math.random(),
      counterRefreshToken: Math.random(),
    };
  },
  methods: {
    save() {
      const sensorObjToSend = _.cloneDeep(this.sensorObj);
      (sensorObjToSend.inputs || []).forEach((inp) => delete inp.validationStatus);
      (sensorObjToSend.counters || []).forEach((c) => delete c.validationStatus);
      this.$emit("save", sensorObjToSend);
      this.closeMe();
    },
    /* UTILITY */
    translateValueType(valueType) {
      return translateDataType(valueType);
    },
    getSQ(sensor) {
      return getNormalizedQualitySignal(sensor);
    },
    getBL(sensor) {
      return getNormalizedBatteryLvl(sensor);
    },
    getOnline(sensor) {
      return isOnline(sensor);
    },
    /***********/
    closeMe() {
      this.$emit("close");
    },
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    addDerivatedInput() {
      this.sensorObj.inputs.push({ source: this.sensor.inputs[0].destination || this.sensor.inputs[0].source, destination: "volume", enabled: false, mesUnit: "l/s" });
    },
    addCounter() {
      this.sensorObj.counters.push({ valueType: countableSources[0], enabled: false, lastTX: null });
    },
    removeDerivatedInput(index) {
      if (index > -1) {
        this.sensorObj.inputs.splice(index, 1);
        this.inputRefreshToken++;
      }
    },
    removeCounter(index) {
      if (index > -1) {
        this.sensorObj.counters.splice(index, 1);
        this.counterRefreshToken++;
      }
    },
    // countableSources: () => countableSources,
  },
  computed: {
    /*  getDataTypesList() {
      return getAvailableDataTypesV2(this.sensor);
    }, */
    staticTypeSensor() {
      // E' un sensore statico, non ha gestione
      return this.sensor && this.sensor.staticType;
    },
    getExistingDestinations() {
      const nativeDestinations = [];
      const derivatedDestinations = [];
      for (const input of this.sensor.inputs) {
        if (!input.destination) {
          nativeDestinations.push(input.source);
        } else {
          derivatedDestinations.push(input.destination);
        }
      }
      return [...nativeDestinations, ...derivatedDestinations];
    },
    hasNativeDistanceInput() {
      return this.sensorObj.inputs.some((inp) => !inp.destination && inp.source === "distance");
    },
    someInvalidInput() {
      return this.sensorObj.inputs.some((inp) => inp.validationStatus === false);
    },
    someInvalidCounter() {
      return this.sensorObj.counters.some((inp) => inp.validationStatus === false);
    },
  },
  /*   watch: {
    device: function (newInput, oldInput) {
      if (newInput) {
        this.tempInput = JSON.parse(JSON.stringify(newInput));
      }
      console.log("Input Watch", newInput);
    },
  }, */
  mounted() {
    this.sensorObj = JSON.parse(JSON.stringify(this.sensor));
  },
};
</script>
<style scoped>
.select-info {
  width: 100%;
  max-width: 100%;
}
.bootstrap-switch {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 0;
}
.has-success:after,
.has-danger:after {
  top: 8px;
}
.add-button {
  padding: 4px;
  right: 0;
  margin: 6px 12px;
  z-index: 2;
}
#main-wrap {
  min-height: 500px;
}
.footer-col {
  height: fit-content;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px;
}
#add-alert-button {
  position: absolute;
  top: 20px;
  right: 27px;
}
</style>
