var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extended-forms",attrs:{"id":"wrap"}},[_c('el-dialog',{staticClass:"min-dialog",attrs:{"visible":_vm.showOutputSettingsModal},on:{"update:visible":function($event){_vm.showOutputSettingsModal=$event}}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[(_vm.showOutputSettingsModal && _vm.outputObj)?_c('OutputSettingsModal',{attrs:{"output":_vm.outputObj,"sensors":_vm.sensors},on:{"close":function($event){_vm.showOutputSettingsModal = false},"save":_vm.saveOutputSettings}}):_vm._e()],1),_c('span'),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"})]),(_vm.outputObj)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('form',[_c('card',[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Riepilogo Output")]),_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.outputObj.name))]),_c('div',{staticClass:"row"},[(_vm.outputObj.enabled !== undefined)?_c('div',{staticClass:"col-12 flex"},[_c('label',{staticClass:"mr-2"},[_vm._v("Output abilitato:")]),_c('base-switch',{attrs:{"type":"info","on-text":"ON","off-text":"OFF"},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.outputObj.enabled),callback:function ($$v) {_vm.$set(_vm.outputObj, "enabled", $$v)},expression:"outputObj.enabled"}})],1):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Nome custom","error":errors[0]},on:{"input":function($event){_vm.unsavedChanges = true}},model:{value:(_vm.outputObj.name),callback:function ($$v) {_vm.$set(_vm.outputObj, "name", $$v)},expression:"outputObj.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('base-button',{attrs:{"wide":true,"block":true,"simple":false,"type":"info"},on:{"click":_vm.openOutputModal}},[_vm._v("Impostazioni vanzate")])],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 flex"},[_c('base-button',{staticClass:"auto-m",attrs:{"type":"success","disabled":!_vm.unsavedChanges || invalid},on:{"click":_vm.saveQuick}},[_vm._v("Salva ")])],1),_c('div',{staticClass:"col-6 flex"},[_c('base-button',{staticClass:"auto-m",attrs:{"type":"warning","disabled":!dirty},on:{"click":_vm.undo}},[_vm._v("Annulla")])],1)])])],2)],1)]}}],null,false,181621706)}):_c('div',{staticClass:"empty-module"},[_c('h3',[_vm._v("Aggiungi/seleziona un output...")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }