<template>
  <div class="row">
    <div class="col-12">
      <ValidationObserver v-slot="{ invalid }">
        <card card-body-classes="table-full-width">
          <div class="row m-0">
            <div class="col-8">
              <form autocomplete="do-not-autofill">
                <card footer-classes="text-right">
                  <div slot="header"><h4 class="card-title">Dettagli sensore</h4></div>
                  <div class="row">
                    <!-- Vendor -->
                    <!-- <label>Vendor:</label> -->
                    <el-select class="col-12 select-info" size="small" :placeholder="'Vendor'" v-model="sensorObj.vendor">
                      <el-option v-for="option in vendors" class="select-info" :value="option" :label="option" :key="option"> </el-option>
                    </el-select>
                    <!-- Modello -->
                    <el-select class="col-12 select-info" size="small" :placeholder="'Model'" v-model="sensorObj.modelName">
                      <el-option v-for="option in modelsByVendor[sensorObj.vendor]" class="select-info" :value="option" :label="option" :key="option"> </el-option>
                    </el-select>
                    <!-- ID -->
                    <ValidationProvider class="col-md-12 select-info" name="ID" :rules="{ required: true, regex: /^\S{3,96}$/ }" v-slot="{ passed, failed }">
                      <base-input required v-model="sensorObj.device" placeholder="* ID" type="ID" :class="[{ 'has-success': passed }, { 'has-danger': failed }]"> </base-input>
                    </ValidationProvider>
                    <div class="col-md-12 category form-category">* Required fields</div>
                  </div>
                </card>
              </form>
            </div>
            <div class="col-4 flex img-col p-0 m-0">
              <img class="auto-m" :src="deviceImg" alt="Device Image" />
            </div>
          </div>
          <template slot="footer">
            <div class="row">
              <div class="col-6 flex">
                <base-button type="success" class="auto-m" @click="close">Chiudi</base-button>
              </div>
              <div class="col-6 flex">
                <base-button type="primary" class="auto-m" @click="saveSensor" :disabled="invalid">{{ existingSensor ? "Salva" : "Aggiungi" }}</base-button>
              </div>
            </div>
          </template>
        </card>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { randomGeo } from "../../../helpers/geo";

export default {
  props: {
    existingSensor: Object,
  },
  components: { [Option.name]: Option, [Select.name]: Select },
  data() {
    return {
      vendors: ["elsys", "hc", "gbs", "generic"],
      // Modelli per per Vendor
      modelsByVendor: {
        elsys: ["ELT2", "ELT2-HP"],
        gbs: ["eth-ind", "MOD-BUS"],
        hc: ["HC-DIST", "HC-DIG"],
        generic: ["FTP-CAMERA"],
      },
      // Oggetto principale di default
      sensorObj: {
        rev: "0",
        device: "",
        modelName: "ELT2",
        vendor: "elsys",
        userConfig: {
          name: "",
        },
        loc: [{ type: "geo" }],
      },
      // Settaggi di default per modelli
      defaultSensorsSettings: {
        ELT2: {
          rev: "0",
          modelName: "ELT2",
          vendor: "elsys",
          userConfig: {
            name: "",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
        "ELT2-HP": {
          rev: "0",
          modelName: "ELT2-HP",
          vendor: "elsys",
          userConfig: {
            name: "",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
        "HC-DIST": {
          rev: "0",
          modelName: "HC-DIST",
          vendor: "hc",
          userConfig: {
            name: "",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
        "HC-DIG": {
          rev: "0",
          modelName: "HC-DIG",
          vendor: "hc",
          userConfig: {
            name: "",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
        "eth-ind": {
          rev: "0",
          modelName: "eth-ind",
          vendor: "gbs",
          userConfig: {
            name: "",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
        "MOD-BUS": {
          rev: "0",
          modelName: "MOD-BUS",
          vendor: "gbs",
          userConfig: {
            name: "",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
        "FTP-CAMERA": {
          rev: "0",
          modelName: "FTP-CAMERA",
          staticType: "FTP-CAMERA",
          vendor: "generic",
          userConfig: {
            name: "TLC",
          },
          loc: [{ type: "geo", coordinates: randomGeo([43.83849, 10.507736], 2000) }],
        },
      },
    };
  },
  methods: {
    submit() {
      console.log("Form has been submitted!");
    },
    saveSensor() {
      this.sensorObj.userConfig["name"] = this.sensorObj.device;
      this.$emit("save", this.sensorObj);
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    deviceImg() {
      switch (this.sensorObj.modelName) {
        case "ELT2":
          return require("../../../../public/img/devices/ELSYS_ELT2.webp");
        case "ELT2-HP":
          return require("../../../../public/img/devices/ELSYS_ELT2-HP.png");
        case "HC-DIG":
          return require("../../../../public/img/devices/HC_HC-DIG.png");
        case "HC-DIST":
          return require("../../../../public/img/devices/HC_HC-DIST.png");
        case "eth-ind":
          return require("../../../../public/img/devices/GBS_ETH-IND.webp");
        case "FTP-CAMERA":
          return require("../../../../public/img/devices/GENERIC_FTP-CAMERA.png");
        case "MOD-BUS":
          return require("../../../../public/img/devices/GBS_MOD-BUS.svg");
        default:
          return undefined;
      }
    },
  },
  watch: {
    "sensorObj.vendor"(newVendor, oldVendor) {
      if (newVendor && oldVendor) {
        this.sensorObj.modelName = this.modelsByVendor[newVendor][0];
        this.sensorObj = JSON.parse(JSON.stringify(this.defaultSensorsSettings[this.sensorObj.modelName]));
      }
    },
  },
  mounted() {
    if (this.existingSensor) {
      this.sensorObj = JSON.parse(JSON.stringify(this.existingSensor));
    }
  },
};
</script>
<style scoped>
.select-info {
  margin: 10px 0;
}
.img-col {
  background-color: rgb(39, 41, 59);
  border-radius: 10px;
}
img {
  max-height: 240px;
}
</style>
