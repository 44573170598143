<template>
  <card type="chart">
    <template slot="header">
      <div class="row">
        <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
          <h5 class="card-category">{{ $t("chart.measuredDataType") }}</h5>
          <h2 class="card-title">{{ chartTitle }}</h2>
        </div>
        <div class="col-sm-6 d-flex d-sm-block">
          <div class="btn-group btn-group-toggle" :class="isRTL ? 'float-left' : 'float-right'" data-toggle="buttons">
            <label
              v-for="(option, index) in traces.categories"
              :key="option.name"
              class="btn btn-sm btn-primary btn-simple"
              :class="{ active: activeCategoryIndex === index }"
              :id="index"
            >
              <input type="radio" @click="switchCategory(index)" name="options" autocomplete="off" :checked="activeCategoryIndex === index" />
              <span class="d-none d-sm-block">{{ option.name }}</span>
              <span class="d-block d-sm-none">
                <i :class="option.icon"></i>
              </span>
            </label>
            <base-button v-if="downloadOption" @click="downloadData()" size="sm" type="info"> <i class="tim-icons icon-cloud-download-93"></i></base-button>
          </div>
        </div>
      </div>
    </template>
    <div class="chart-area">
      <line-chart
        style="height: 100%"
        ref="bigChart"
        :chart-data="traces.chartData"
        :gradient-colors="traces.gradientColors"
        :gradient-stops="traces.gradientStops"
        :extra-options="traces.extraOptions"
      >
      </line-chart>
    </div>
  </card>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import config from "@/config";
import { downloadXLSLX } from "../../helpers/download";

export default {
  name: "DataChartGBS",
  components: {
    LineChart
  },
  props: {
    downloadOption: {
      type: Boolean,
      default: true
    },
    chartTitle: String,
    values: Array,
    filledValues: Array,
    dates: Array,
    mesUnit: String,
    categories: Array,
    alerts: {
      type: Array,
      default: () => []
    },
    deviceID: String,
    columnName: String,
    colors: Array,
    showChartAnimation: Boolean
  },
  data() {
    return {
      activeCategoryIndex: 0,
      basicLineChartOptions: {
        activeIndex: 0,
        extraOptions: {
          layout: {
            padding: 10
          },
          hover: {
            animationDuration: 0
          },
          maintainAspectRatio: false,
          /* animation: true, */
          parsing: false,
          normalized: true,
          spanGaps: true,
          /* plugins: {
            decimation: {
              enabled: true,
              algorithm: "min-max"
            }
          }, */
          legend: {
            display: false
          },
          responsive: true,
          tooltips: {
            backgroundColor: "#f5f5f5",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest"
          },
          scales: {
            yAxes: [
              {
                offset: true,
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: true,
                  color: "rgba(225,255,255,0.15)",
                  zeroLineColor: "transparent"
                },
                ticks: {
                  padding: 10,
                  fontColor: "#9a9a9a",
                  autoSkip: true,
                  autoSkipPadding: 20,
                  maxRotation: 0,
                  minRotation: 0
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  labelString: "Date"
                },
                barPercentage: 1.6,
                type: "time",
                /* time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM DD'
                    }
                }, */
                time: {
                  displayFormats: {
                    millisecond: "MMM DD",
                    second: "MMM DD",
                    minute: "MMM DD",
                    hour: "MMM DD",
                    day: "MMM DD",
                    week: "MMM DD",
                    month: "MMM DD",
                    quarter: "MMM DD",
                    year: "MMM DD"
                  }
                },
                gridLines: {
                  drawBorder: true,
                  color: "rgba(225,255,255,0.15)",
                  zeroLineColor: "transparent"
                },
                ticks: {
                  padding: 10,
                  fontColor: "#9a9a9a",
                  autoSkip: true,
                  autoSkipPadding: 120,
                  maxRotation: 0,
                  minRotation: 0
                }
              }
            ]
          }
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
        chartData: {
          datasets: [
            {
              fill: false,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 2.5
            }
          ]
        }
      }
    };
  },
  computed: {
    annotationsByAlerts() {
      return this.alerts.map(al => {
        return {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          borderColor: al.alertType === "up" ? (al.notifications.includes("sms") ? "red" : "orange") : al.notifications.includes("sms") ? "#cc0081" : "#ad5ca1",
          value: al.threshold,
          borderWidth: 1,
          borderDash: 0,
          label: {
            content:
              this.$t("dataChart.alert") +
              " " +
              al.alertType.toUpperCase() +
              " " +
              al.threshold +
              " " +
              this.mesUnit +
              (al.enabled ? "" : " (" + this.$t("dataChart.disabled") + ")"),
            enabled: true
          }
        };
      });
    },
    traces() {
      let valuesDataSets = (this.values || []).map((vl, index) => {
        return {
          ...this.basicLineChartOptions.chartData.datasets[0],
          data: vl,
          borderColor: this.colors && this.colors[index] ? this.colors[index] : config.colors.primary,
          pointBackgroundColor: this.colors && this.colors[index] ? this.colors[index] : config.colors.primary
        };
      });
      let marginDataSets = (this.filledValues || []).map((vl, index) => {
        return {
          ...this.basicLineChartOptions.chartData.datasets[0],
          data: vl,
          borderColor: "rgba(100, 71, 133, 0.5)",
          backgroundColor: "rgba(100, 71, 133, 0.5)",
          pointBackgroundColor: "rgba(100, 71, 133, 0.5)",
          pointRadius: 0,
          fill: 1
        };
      });
      let finalDataSets = [...marginDataSets, ...valuesDataSets];
      return {
        ...this.basicLineChartOptions,
        extraOptions: {
          ...this.basicLineChartOptions.extraOptions,
          animation: {
            duration: this.showChartAnimation ? 1000 : 0
          },
          scales: {
            ...this.basicLineChartOptions.extraOptions.scales,
            yAxes: [
              {
                ...this.basicLineChartOptions.extraOptions.scales.yAxes[0],
                scaleLabel: {
                  display: true,
                  labelString: this.mesUnit
                }
              }
            ]
          },
          annotation: {
            annotations: this.annotationsByAlerts
          }
        },
        categories: this.categories,
        chartData: {
          ...this.basicLineChartOptions.chartData,
          labels: this.dates,
          datasets: finalDataSets
        }
      };
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  methods: {
    /*     minValue(ctx) {
      const dataset = ctx.chart.data.datasets[0];
      const min = dataset.data.reduce((max, point) => Math.min(point, max), Infinity);
      return isFinite(min) ? min : 0;
    },

    maxValue(ctx) {
      const datasets = ctx.chart.data.datasets;
      const count = datasets[0].data.length;
      let max = 0;
      for (let i = 0; i < count; i++) {
        let sum = 0;
        for (const dataset of datasets) {
          sum += dataset.data[i];
        }
        max = Math.max(max, sum);
      }
      return max;
    }, */
    switchCategory(index) {
      this.activeCategoryIndex = index;
    },
    downloadData() {
      this.downloadExcelFile(this.dates, this.values[0], this.columnName, this.deviceID, this.columnName + " " + this.deviceID);
    },
    downloadExcelFile(dates, mesValues, columnName, device, fileTitle) {
      const values = [];
      const headers = ["Device", "Date", "Time", columnName];
      values.push(headers);
      const title = fileTitle;
      for (let j = 0; j < dates.length; j++) {
        const value = mesValues[j];
        if (value != null && value != undefined) {
          const date = new Date(dates[j]).toLocaleDateString().replace(/,/g, "");
          const time = new Date(dates[j]).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }).replace(/,/g, "");
          const value = mesValues[j];
          values.push([device, date, time, Number(value.toFixed(2))]);
        }
      }
      downloadXLSLX(values, title);
    }
  }
};
</script>
